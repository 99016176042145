let timeoutHnd = null

export const actions = {
  setError({ commit }, payload: string | { message: string, title?: string, timeout?: number }) {
    let message: string
    let title: string | undefined
    let timeout: number | null = null

    if (typeof payload === 'string') {
      message = payload
    } else {
      message = payload.message
      title = payload.title
      timeout = payload.timeout
    }

    commit('setError', { message, title })

    if (timeout !== null) {
      setTimeout(() => {
        commit('resetToast')
      }, timeout)
    }
  },
  setSuccess({ commit }, payload: string | { message: string, title?: string, timeout?: number | null }) {
    let message: string
    let title: string | undefined
    let timeout: number | null = 5000

    if (typeof payload === 'string') {
      message = payload
    } else {
      message = payload.message
      title = payload.title
      timeout = payload.timeout !== undefined ? payload.timeout : 5000
    }

    if (timeoutHnd) {
      clearTimeout(timeoutHnd)
    }

    commit('setSuccess', { message, title })

    if (timeout !== null) {
      timeoutHnd = setTimeout(() => {
        commit('resetToast')
      }, timeout)
    }
  },
}
