<script>
import { capitalize } from '@sancare/ui-frontend-commons/src/misc/string-utils'
import Slider from 'vue-slider-component'


export default {
  components: {
    'vue-slider': Slider
  },
  props: {
    filterData: { type: Object, required: true },
  },
  emits: ['add-filter'],
  data() {
    const params = this.filterData.range
    const min = params.less ? `<${params.min}` : params.min
    const max = params.more ? `>${params.max}` : params.max
    const subFiltersValues = {}

    if (this.filterData.subFilters) {
      this.filterData.subFilters.forEach((filter) => {
        if (filter.default) {
          subFiltersValues[filter.name] = filter.default
        } else {
          subFiltersValues[filter.name] = filter.options ? filter.options[0] : ''
        }
      })
    }

    return {
      value: [min, max],
      subFiltersValues: subFiltersValues,
    }
  },
  computed: {
    rangeList() {
      const params = this.filterData.range
      const list = []
      let value = params.min

      if (params.less) {
        list.push(`<${params.min}`)
      }
      while (value <= params.max) {
        list.push(value)
        value += params.interval
      }
      if (params.more) {
        list.push(`>${params.max}`)
      }

      return (list)
    },
    rangeFormat() {
      return this.filterData.range.unit ? `{value}${this.filterData.range.unit}` : '{value}'
    }
  },
  watch: {
    'filterData.name': function() {
      const params = this.filterData.range
      const min = params.less ? `<${params.min}` : params.min
      const max = params.more ? `>${params.max}` : params.max

      this.resetRange(min, max)
    },
  },
  methods: {
    setRange() {
      const name = this.filterData.name
      let type = this.filterData.format
      const params = this.filterData.range
      const min = params.less ? `<${params.min}` : params.min
      const max = params.more ? `>${params.max}` : params.max
      let value = this.value

      if (this.filterData.subFilters) {
        this.filterData.subFilters.forEach((filter) => {
          type = type.replace(`{${filter.name}}`, capitalize(this.subFiltersValues[filter.name]))
        })
        type = type.charAt(0).toLowerCase() + type.slice(1)
      }

      if (value.length && (value[0] !== min || value[1] !== max)) {
        value = `${value[0]}_${value[1]}`
        this.$emit('add-filter', { name, type, value })
      }
    },
    resetRange(min, max) {
      this.value = [min, max]
    }
  },
}
</script>

<template>
  <div class="row align-items-center">
    <div class="col">
      <vue-slider
        :key="filterData.name"
        v-model="value"
        :v-data="rangeList"
        :formatter="rangeFormat"
        :tooltip="'always'"
        range
      />
    </div>
    <div class="col-auto">
      <button
        class="btn btn-primary"
        type="button"
        @click="setRange"
      >
        Ok
      </button>
    </div>
  </div>
</template>
