import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { state } from '@/store/modules/criterion/state'
import { PayloadWithSuccessMut, VersionningPayload } from '@/store/types'

import { Criterion } from '../search/types'

interface criteriaJson extends VersionningPayload {
  criteria: Criterion[]
}

interface ExportCriteriaPayload extends PayloadWithSuccessMut {
  ids: number[]
}

interface ImportCriteriaPayload extends PayloadWithSuccessMut {
  criteriaJson: criteriaJson
}

export const actions = {
  exportCriteria({ commit }, payload: ExportCriteriaPayload) {
    return Api.request(
      state.requests.exportCriteria,
      {
        body: payload,
        successMut: (response) => response,
      },
      commit,
    )
  },
  importCriteria({ commit, dispatch }, payload: ImportCriteriaPayload) {
    return Api.request(
      state.requests.importCriteria,
      {
        body: payload,
        successMut: () => {
          dispatch('toast/setSuccess', 'Import des critères réussi !', { root: true })
          dispatch('fetchCriteria', { force: true }, { root: true })
        },
        errorMut: (error) => {
          dispatch('toast/setError', {
            message: error.message ?? 'Une erreur est survenue lors de l\'import des critères.',
            title: 'Erreur lors de l\'import',
            timeout: 15000,
          }, { root: true })
        },
      },
      commit,
    )
  },
}
