import _ from 'lodash'

import criterionPermissions from './criterion'
import studyPermissions from './study'
import studyNodePermissions from './studyNode'
import studyStepPermissions from './studyStep'
import userPermissions from './user'
import variablePermissions from './variable'

// This is a copy of the permission graph configured in the backend in security.yaml
// Beware to apply any edit in both files.

export enum ProfileEnum {
  ROLE_SANCARE_DEV = 'ROLE_SANCARE_DEV',
  ROLE_SANCARE_ADMIN = 'ROLE_SANCARE_ADMIN',
  ROLE_RESEARCHER = 'ROLE_RESEARCHER',
  ROLE_USER_ADMIN = 'ROLE_USER_ADMIN',
  ROLE_SANCARE_VIEWER = 'ROLE_SANCARE_VIEWER',
  ROLE_DATA_PROVIDER = 'ROLE_DATA_PROVIDER',
  ROLE_MONITORING = 'ROLE_MONITORING',
  ROLE_BOT = 'ROLE_BOT',
}

export enum RoleEnum {
  ROLE_ADMIN_MANAGER = 'ROLE_ADMIN_MANAGER',
  ROLE_DATA_PROVIDER = 'ROLE_DATA_PROVIDER',
  ROLE_DOWNLOAD_SOURCEFILE = 'ROLE_DOWNLOAD_SOURCEFILE',
  ROLE_MONITORING = 'ROLE_MONITORING',
  ROLE_SANCARE_SETTING_UPDATER = 'ROLE_SANCARE_SETTING_UPDATER',
  ROLE_SETTING_UPDATER = 'ROLE_SETTING_UPDATER',
  ROLE_STAY_EXPORTER = 'ROLE_STAY_EXPORTER',
  ROLE_STAY_READER = 'ROLE_STAY_READER',
  ROLE_STUDY_CREATOR = 'ROLE_STUDY_CREATOR',
  ROLE_STUDY_ADMIN = 'ROLE_STUDY_ADMIN',
  ROLE_STUDY_EXPORT_IMPORT = 'ROLE_STUDY_EXPORT_IMPORT',
  ROLE_SYSTEM_USER_MANAGER = 'ROLE_SYSTEM_USER_MANAGER',
  ROLE_USER_MANAGER = 'ROLE_USER_MANAGER',
  ROLE_USER_VIEWER = 'ROLE_USER_VIEWER',
}

const profilesRoles = {
  [ProfileEnum.ROLE_SANCARE_DEV]: [
    RoleEnum.ROLE_ADMIN_MANAGER,
    RoleEnum.ROLE_DATA_PROVIDER,
    RoleEnum.ROLE_DOWNLOAD_SOURCEFILE,
    RoleEnum.ROLE_MONITORING,
    RoleEnum.ROLE_SANCARE_SETTING_UPDATER,
    RoleEnum.ROLE_SETTING_UPDATER,
    RoleEnum.ROLE_STAY_EXPORTER,
    RoleEnum.ROLE_STAY_READER,
    RoleEnum.ROLE_STUDY_CREATOR,
    RoleEnum.ROLE_STUDY_ADMIN,
    RoleEnum.ROLE_STUDY_EXPORT_IMPORT,
    RoleEnum.ROLE_SYSTEM_USER_MANAGER,
    RoleEnum.ROLE_USER_MANAGER,
    RoleEnum.ROLE_USER_VIEWER,
  ],
  [ProfileEnum.ROLE_SANCARE_ADMIN]: [
    RoleEnum.ROLE_ADMIN_MANAGER,
    RoleEnum.ROLE_DATA_PROVIDER,
    RoleEnum.ROLE_DOWNLOAD_SOURCEFILE,
    RoleEnum.ROLE_MONITORING,
    RoleEnum.ROLE_SANCARE_SETTING_UPDATER,
    RoleEnum.ROLE_SETTING_UPDATER,
    RoleEnum.ROLE_STAY_EXPORTER,
    RoleEnum.ROLE_STAY_READER,
    RoleEnum.ROLE_STUDY_CREATOR,
    RoleEnum.ROLE_STUDY_ADMIN,
    RoleEnum.ROLE_STUDY_EXPORT_IMPORT,
    RoleEnum.ROLE_SYSTEM_USER_MANAGER,
    RoleEnum.ROLE_USER_MANAGER,
    RoleEnum.ROLE_USER_VIEWER,
  ],
  [ProfileEnum.ROLE_RESEARCHER]: [
    RoleEnum.ROLE_STUDY_CREATOR,
  ],
  [ProfileEnum.ROLE_USER_ADMIN]: [
    RoleEnum.ROLE_DOWNLOAD_SOURCEFILE,
    RoleEnum.ROLE_MONITORING,
    RoleEnum.ROLE_SETTING_UPDATER,
    RoleEnum.ROLE_STAY_EXPORTER,
    RoleEnum.ROLE_STAY_READER,
    RoleEnum.ROLE_STUDY_CREATOR,
    RoleEnum.ROLE_STUDY_ADMIN,
    RoleEnum.ROLE_USER_MANAGER,
    RoleEnum.ROLE_USER_VIEWER,
  ],
  [ProfileEnum.ROLE_SANCARE_VIEWER]: [
    RoleEnum.ROLE_STAY_READER
  ],
  [ProfileEnum.ROLE_DATA_PROVIDER]: [],
  [ProfileEnum.ROLE_MONITORING]: [],
  [ProfileEnum.ROLE_BOT]: [],
}

export const profileLabels = {
  [ProfileEnum.ROLE_SANCARE_DEV]: 'Développeur Sancare',
  [ProfileEnum.ROLE_SANCARE_VIEWER]: 'Utilisateur Sancare',
  [ProfileEnum.ROLE_SANCARE_ADMIN]: 'Admin Sancare',
  [ProfileEnum.ROLE_USER_ADMIN]: 'Administrateur',
  [ProfileEnum.ROLE_RESEARCHER]: 'Chercheur',
}

function hasPermission(expectedPermission: RoleEnum, testedProfile: string): boolean {
  return _.has(profilesRoles, testedProfile) && profilesRoles[testedProfile].indexOf(expectedPermission) !== -1
}

export {
  hasPermission,
  criterionPermissions,
  studyPermissions,
  studyStepPermissions,
  studyNodePermissions,
  userPermissions,
  variablePermissions,
}
