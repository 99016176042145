import dayjs, { Dayjs } from 'dayjs'

import { Job } from '@/store/modules/job/types'

// todo move to ui-frontend-commons
export function showDuration(startDate: Dayjs, endDate: Dayjs): string {
  const seconds = endDate.diff(startDate, 'second')
  if (seconds < 60) {
    return `${seconds}s`
  }
  const minutes = Math.floor(seconds/60)
  const remainingSeconds = seconds-(minutes*60)
  if (minutes < 60) {
    return `${minutes}m ${remainingSeconds}s`
  }
  const hours = Math.floor(minutes/60)
  const remainingMinutes = minutes-(hours*60)
  if (hours < 24) {
    return `${hours}h ${remainingMinutes}m ${remainingSeconds}s`
  }
  const days = Math.floor(hours/24)
  const remainingHours = hours-(days*24)
  return `${days}j ${remainingHours}h ${remainingMinutes}m ${remainingSeconds}s`
}

export function showJobDuration(job: Job): string {
  if (job.startedAt === null) {
    return ''
  }
  return showDuration(job.startedAt, job.endedAt ?? dayjs())
}
