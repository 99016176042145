import Api from '@sancare/ui-frontend-commons/src/misc/api'

import { state } from '@/store/modules/variable/state'
import { PayloadWithSuccessMut, VersionningPayload } from '@/store/types'
import { Variable } from '@/variables/types'

interface ExportVariablesPayload extends PayloadWithSuccessMut {
  ids: number[]
}

interface variablesJson extends VersionningPayload {
  variables: Variable[]
}

interface ImportVariablesPayload extends PayloadWithSuccessMut {
  variablesJson: variablesJson
}

export const actions = {
  exportVariables({ commit }, payload: ExportVariablesPayload) {
    return Api.request(
      state.requests.exportVariables,
      {
        body: payload,
        successMut: (response) => response,
      },
      commit,
    )
  },
  importVariables({ commit, dispatch }, payload: ImportVariablesPayload) {
    return Api.request(
      state.requests.importVariables,
      {
        body: payload,
        successMut: () => {
          dispatch('toast/setSuccess', 'Import des variables réussi !', { root: true })
          dispatch('fetchVariables', { force: true }, { root: true })
        },
        errorMut: (error) => {
          dispatch('toast/setError', {
            message: error.message ?? 'Une erreur est survenue lors de l\'import des variables.',
            title: 'Erreur lors de l\'import',
            timeout: 15000,
          }, { root: true })
        },
      },
      commit,
    )
  },
}
