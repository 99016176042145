import { ToastColorEnum } from '@sancare/ui-frontend-commons/src/components/types'

import { ToastState } from '@/store/modules/toast/types'

export const mutations = {
  resetToast(state: ToastState) {
    state.title = ''
    state.message = ''
    state.color = ToastColorEnum.NONE
    state.isOpen = false
  },
  setColor(state: ToastState, color: ToastColorEnum) {
    state.color = color
  },
  setIsOpen(state: ToastState, isOpen: boolean) {
    state.isOpen = isOpen
  },
  setMessage(state: ToastState, message: string) {
    state.message = message
  },
  setTitle(state: ToastState, title: string) {
    state.title = title
  },
  setError(state: ToastState, payload: string | { message: string, title?: string }) {
    if (typeof payload === 'string') {
      state.title = 'Erreur'
      state.message = payload
    } else {
      state.title = payload.title || 'Erreur'
      state.message = payload.message
    }
    state.color = ToastColorEnum.DANGER
    state.isOpen = true
  },
  setSuccess(state: ToastState, payload: string | { message: string, title?: string }) {
    if (typeof payload === 'string') {
      state.title = 'Succès'
      state.message = payload
    } else {
      state.title = payload.title || 'Succès'
      state.message = payload.message
    }
    state.color = ToastColorEnum.SUCCESS
    state.isOpen = true
  }
}
