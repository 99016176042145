import { ApiRequest } from '@sancare/ui-frontend-commons/src/misc/api-request'

import { CriterionState } from '@/store/modules/criterion/types'

const baseUrl = '/api/criterion'

const state = new CriterionState()
state.requests = {
  exportCriteria: new ApiRequest('exportCriteria', 'POST', `${baseUrl}/export-json`),
  importCriteria: new ApiRequest('importCriteria', 'POST', `${baseUrl}/import-json`),
}

export { state }
